import React, {Component} from 'react';
import "./page.css";
import "./index.css";
import "./hamNav.css";
import "./home.css";
import touchHouse from './images/AdobeStock_217569435.jpeg';
import instaLogo from './images/glyph-logo_May2016.png';
import linkedLogo from './images/Logo-2C-34px-R.png';
import faceLogo from './images/f_logo_RGB-Blue_58.png';

class Home extends Component {
    render() {
        return(
            <React.Fragment>
                <div className="container">
                    <div className="photo">
                        <img src={touchHouse} alt = "None"/>
                        <div className="centered">
                            <h1>FuturoWare...See What the Future Has In-Store!</h1>
                            <h2>From Internet of Things products,</h2>
                            <h2>to Fire Protection Software and Technical Services,</h2>
                            <h2>FuturoWare has a Solution for <u>YOU</u>!</h2>
                            <div className="buttonCenter">
                            </div>
                        </div>
                    </div>
                    <div className="comment">
                        <div className="flexContainer">
                            <h2>Social Media</h2>
                            <div>
                                <button>
                                    <a href="https://www.instagram.com/futuroware/">
                                        <img src={instaLogo} className="instaLogo" alt="Instagram Logo"/>
                                    </a>
                                </button>
                            </div>
                            <div>
                                <button>
                                    <a href="https://www.linkedin.com/company/futuroware/?viewAsMember=true">
                                        <img src={linkedLogo} className="linkedLogo" alt="LinkedIn Logo"/>
                                    </a>
                                </button>
                            </div>
                            <div>
                                <button>
                                    <a href="https://www.facebook.com/futuroware">
                                        <img src={faceLogo} className="faceLogo" width="70px" alt="FaceBook Logo"/>
                                    </a>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Home;