import React from 'react';
import ReactDOM from 'react-dom';
import Main from './Main';
import './index.css';
import './grid.css';
import {createStore} from 'redux';
import {Provider} from 'react-redux';
import counter from './reducer';

//Store
let store = createStore(counter);

ReactDOM.render(
    <Provider store={store}>
        <Main/>
    </Provider>
    ,
    document.getElementById('root')
);