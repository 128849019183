import React, {Component} from 'react';
import "./pageProds.css";
import "./index.css";
import "./hamNav.css";
import "./home.css";
import outlineHouseR from './images/AdobeStock_199176087.jpeg';
import nuclearStation from './images/AdobeStock_94979782.jpeg';
import instaLogo from "./images/glyph-logo_May2016.png";
import linkedLogo from "./images/Logo-2C-34px-R.png";
import faceLogo from "./images/f_logo_RGB-Blue_58.png";
import fpeHanaDash from "./images/dashboard.png";
import fpeHanaForm from "./images/impairment.png";
import saSign from "./images/re_sign.png";
import saApp from "./images/sa_app.png";
import fpeApp from "./images/FPE_banner.jpg";
import saSinglePage from './images/Sign-A-Lert Single Page -Urban Scheme.png';
import fpeLargePic from './images/Main Scroll.PNG';
import "./products.css";

class tempProducts extends Component {
    render() {
        return(
            <div className="containerProds">
                <div className="photoProds">
                    <img src={nuclearStation} alt = "None"/>
                    <div className="centeredProd">
                        <div className="fpeText">
                            <h1>Fire Protection Essentials</h1>
                            <h3>Fire Protection Essentials (FPE)
                                is a fully integrated suite of fire
                                protection applications built on the
                                SAP HANA database.  With fully customizable
                                apps, dashboards and reports, important actions
                                and data will never be missed; from the field
                                to the boardroom.</h3>
                        </div>
                        <img className="fpeHanaDash" src={fpeHanaDash} alt="Hana Dashboard"/>
                        <img className="fpeHanaForm" src={fpeHanaForm} alt="Hana Form"/>
                        <div className="fpeVideo">
                            <iframe width="420" height="315"
                                    src="https://www.youtube.com/embed/mZ5g9X6ZdXo"  frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
                            </iframe>
                        </div>
                        <img className="fpeSinglePage" src={fpeApp} alt="none"/>
                        <img className="fpeLargePic" src={fpeLargePic} alt="none"/>
                    </div>
                </div>
                    <div className="photo1Prods">
                        <img src={outlineHouseR} alt = "None"/>
                        <div className="centeredProd">
                            <div className="fpeText">
                                <h1>Sign-A-Lert</h1>
                                <h3>Sign-A-Lert attaches to any portable
                                    sign. Sign-A-Lert leverages the power of the
                                    Internet of Things (IoT) to immediately notify
                                    you if your sign has fallen, been turned or stolen.
                                    Equipped with Bluetooth Beacon, Sign-A-Lert turns your
                                    signs into a powerful marketing partner.</h3>
                            </div>
                            <img className="fpeHanaDash" src={saSign} alt="Sign"/>
                            <img className="fpeHanaForm" id="saApp" src={saApp} alt="App"/>
                            <div className="fpeVideo">
                                <iframe width="420" height="315"
                                        src="https://www.youtube.com/embed/c3Y54uA5qDY"  frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
                                </iframe>
                            </div>
                            <img className="saSinglePage" src={saSinglePage} alt="none"/>
                        </div>
                    </div>
                <div className="commentProds">
                    <div className="flexContainer">
                        <h2>Social Media</h2>
                        <div>
                            <button>
                                <a href="https://www.instagram.com/futuroware/">
                                    <img src={instaLogo} className="instaLogo" alt="Instagram Logo"/>
                                </a>
                            </button>
                        </div>
                        <div>
                            <button>
                                <a href="https://www.linkedin.com/company/futuroware/?viewAsMember=true">
                                    <img src={linkedLogo} className="linkedLogo" alt="LinkedIn Logo"/>
                                </a>
                            </button>
                        </div>
                        <div>
                            <button>
                                <a href="https://www.facebook.com/futuroware">
                                    <img src={faceLogo} className="faceLogo" width="70px" alt="FaceBook Logo"/>
                                </a>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default tempProducts;