import React, {PureComponent} from 'react';
import {
    Route,
    NavLink,
    HashRouter
} from 'react-router-dom';
import {FaAlignLeft} from "react-icons/all";
import Home from './Home';
import Products from './Products';
import Services from './Services';
import Contact from './Contact';
import './hamNav.css';

class Main extends PureComponent {
    state = {
        toggle:false,
    }
    Toggle = () => {
        this.setState({toggle: !this.state.toggle})
    }
    render() {
        return (
            <HashRouter>
                <div>
                    <ul className="header">
                        <div className="header-grid">
                            <div className="header-grid-item1">
                                <button className="NavButton" onClick={this.Toggle}>
                                    <FaAlignLeft/>
                                </button>
                                <ul className={this.state.toggle ? "links show-nav": "links"}>
                                    <div className="linkItems">
                                        <li className="linkItem" onClick={this.Toggle}><NavLink exact to='/'>FuturoWare</NavLink></li>
                                        <li className="linkItem" onClick={this.Toggle}><NavLink to='/Products'>Products</NavLink></li>
                                        <li className="linkItem" onClick={this.Toggle}><NavLink to='/Services'>Services</NavLink></li>
                                        <li className="linkItem" onClick={this.Toggle}><NavLink to='/Contact'>Contact</NavLink></li>
                                    </div>
                                </ul>
                            </div>
                            <div className="header-grid-item2">
                                <h1>FuturoWare</h1>
                            </div>
                            <div className="header-grid-item2">
                                <li></li>
                            </div>
                        </div>
                    </ul>
                    <div className="home-content">
                        <Route exact path='/' component={Home}/>
                    </div>
                    <div className="products-content">
                        <Route path='/Products' component={Products}/>
                    </div>
                    <div className="services-content">
                        <Route path='/Services' component={Services}/>
                    </div>
                    <div className="contact-content">
                        <Route path='/Contact' component={Contact}/>
                    </div>
                </div>
            </HashRouter>
        );
    }
}

export default Main;