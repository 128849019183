import React, {Component} from 'react';
import gpsLocation from "./images/AdobeStock_83341471.jpeg";
import instaLogo from "./images/glyph-logo_May2016.png";
import linkedLogo from "./images/Logo-2C-34px-R.png";
import faceLogo from "./images/f_logo_RGB-Blue_58.png";
import './contact.css';

class Contact extends Component {
    render() {
        return(
            <React.Fragment>
                <div className="container">
                    <div className="photo">
                        <img src={gpsLocation} alt = "None"/>
                        <div className="centered">
                            <section className="contact" id="contact">
                                <div className="contactBlock">
                                    <form className="email_form" action="php/mail.php" method="POST">
                                        <h3><b>Email FuturoWare</b></h3>
                                            <p>
                                                <input className="email_cl"
                                                       name="email"
                                                       size="32"
                                                       maxLength="32"/>
                                                       <br/>
                                                <b>Your</b> Email Address*
                                            </p>
                                            <p>
                                                <input className="email_cl"
                                                       name="realname"
                                                       size="32"
                                                       maxLength="32"/>
                                                <br/>
                                                <b>Your</b> Name*
                                            </p>
                                            <p>
                                                <input className="email_cl"
                                                       name="company"
                                                       size="32"
                                                       maxLength="32"/>
                                                <br/>
                                                <b>Your</b> Company*
                                            </p>
                                            <p>
                                                <input className="email_cl"
                                                       name="phone"
                                                       size="32"
                                                       maxLength="32"/>
                                                <br/>
                                                <b>Your</b> Phone Number
                                            </p>
                                            <p>
                                                <input className="email_cl"
                                                       name="title"
                                                       size="32"
                                                       maxLength="32"/>
                                                <br/>
                                                <b>Your</b> Title*
                                            </p>
                                            <textarea className="email_cl"
                                                      name="body"
                                                      rows="5"
                                                      cols="32">
                                            </textarea>
                                            <p>
                                                <i>Please tell us how we can help <b>you*</b></i>
                                            </p>
                                            <p>* = Required</p>
                                            <p>
                                                <input className="btn btn-success" type="submit" name="send"
                                                       value="Submit"/>
                                            </p>
                                    </form>
                                </div>
                            </section>
                        </div>
                    </div>

                    <div className="comment">
                        <div className="flexContainer">
                            <h2>Social Media</h2>
                            <div>
                                <button>
                                    <a href="https://www.instagram.com/futuroware/">
                                        <img src={instaLogo} className="instaLogo" alt="Instagram Logo"/>
                                    </a>
                                </button>
                            </div>
                            <div>
                                <button>
                                    <a href="https://www.linkedin.com/company/futuroware/?viewAsMember=true">
                                        <img src={linkedLogo} className="linkedLogo" alt="LinkedIn Logo"/>
                                    </a>
                                </button>
                            </div>
                            <div>
                                <button>
                                    <a href="https://www.facebook.com/futuroware">
                                        <img src={faceLogo} className="faceLogo" width="70px" alt="FaceBook Logo"/>
                                    </a>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Contact;