import React, {Component} from 'react';
import dwtnBuilding from "./images/AdobeStock_167198961.jpeg";
import instaLogo from "./images/glyph-logo_May2016.png";
import linkedLogo from "./images/Logo-2C-34px-R.png";
import faceLogo from "./images/f_logo_RGB-Blue_58.png";
import "./page.css";
import "./services.css";
import "./hamNav.css";

class Services extends Component {
    render() {
        return(
            <div className="container">
                <div className="photoServices">
                    <img src={dwtnBuilding} alt = "None"/>
                    <div className="centeredServices">
                        <div className="global">
                            <h1>GLOBAL SERVICES</h1>
                        </div>
                        <div className="classical">
                            <h2>CLASSICAL FIRE PROTECTION</h2>
                            <h3>Code and Regulatory Compliance</h3>
                            <h3>Hazards Analysis and Engineering Evaluations</h3>
                            <h3>Fire Protection and Fire Safety Analysis</h3>
                            <h3>Facility Life-Safety Design</h3>
                            <h3>Fire Modeling</h3>
                            <h3>and more...</h3>
                        </div>
                        <div className="appr">
                            <h2>NUCLEAR</h2>
                            <h3>Safe Shutdown / NSCA Modeling</h3>
                            <h3>Circuit Analysis</h3>
                            <h3>Calculation Creation / Update</h3>
                            <h3>and more...</h3>
                        </div>
                        <div className="sys">
                            <h2>SYSTEM AND PROGRAM MANAGEMENT</h2>
                            <h3>Procedure Development and Training</h3>
                            <h3>Development of Licensing Docs and Changes</h3>
                            <h3>Surveillance Optimization and Analytics</h3>
                            <h3>and more...</h3>
                        </div>
                        <div className="elec">
                            <h2>ELECTRICAL</h2>
                            <h3>Protection, Coordination, Assoc. Circuits</h3>
                            <h3>and more...</h3>
                        </div>
                    </div>
                </div>
                <div className="commentServices">
                    <div className="flexContainer">
                        <h2>Social Media</h2>
                        <div>
                            <button>
                                <a href="https://www.instagram.com/futuroware/">
                                    <img src={instaLogo} className="instaLogo" alt="Instagram Logo"/>
                                </a>
                            </button>
                        </div>
                        <div>
                            <button>
                                <a href="https://www.linkedin.com/company/futuroware/?viewAsMember=true">
                                    <img src={linkedLogo} className="linkedLogo" alt="LinkedIn Logo"/>
                                </a>
                            </button>
                        </div>
                        <div>
                            <button>
                                <a href="https://www.facebook.com/futuroware">
                                    <img src={faceLogo} className="faceLogo" width="70px" alt="FaceBook Logo"/>
                                </a>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Services;